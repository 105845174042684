import React from "react"
// import PropTypes from 'prop-types'
// import styled from 'styled-components'
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"
import SliceZone from "components/SliceZone"
import { linkResolver } from "utils/linkResolver"

function Page({ data: { prismic, site }, ...props }) {
  const { page, site_settings } = prismic
  if (!page) return null
  const { body } = page
  const {
    meta_title,
    meta_description,
    meta_images,
    _meta: { lang },
  } = page
  const { siteUrl, ...defaultMeta } = site.defaultMeta
  const uri = linkResolver(page._meta)

  return (
    <>
      <SEO
        lang={lang}
        slug={uri}
        title={meta_title}
        description={meta_description}
        images={meta_images}
        defaultMeta={defaultMeta}
        hideFromGoogle
      />
      <Layout site_settings={site_settings} lang={lang}>
        <SliceZone
          slices={body}
          pageInfo={{ uri, host: siteUrl, title: page.title[0].text }}
        />
      </Layout>
    </>
  )
}

export default Page

Page.defaultProps = {}

Page.propTypes = {}

export const query = graphql`
  query PrismicPage($lang: String!, $uid: String!, $isProduction: Boolean!) {
    site {
      defaultMeta: siteMetadata {
        ...SiteMetaFragment
      }
    }
    prismic {
      site_settings(lang: $lang, uid: "site-settings") {
        copyright_information
        ...SiteNavigation
        ...FooterNavigation
        ...SocialMediaLinks
      }
      page(lang: $lang, uid: $uid) {
        _meta {
          type
          lang
          uid
        }
        title
        meta_title
        meta_description
        meta_images {
          image
          imageSharp @include(if: $isProduction) {
            childImageSharp {
              id
              main: fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        show_in_sitemap
        body {
          ... on PRISMIC_PageBodyHero_section {
            type
            label
            primary {
              content: hero_content
              headline: hero_headline
              pre_headline: hero_pre_headline
              image: hero_image
              imageSharp: hero_imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 960) {
                    ...GatsbyImageSharpFixed
                  }
                  mobile: fixed(width: 480) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyCard_repeater {
            type
            label
            primary {
              card_repeater_content
              card_repeater_title
              card_repeater_content_after
              columns
            }
            fields {
              content {
                ... on PRISMIC_Page {
                  title
                  _meta {
                    type
                    id
                    uid
                    lang
                  }
                }
              }
              background_image
              background_imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 300) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 300) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              content_section_id
            }
          }
          ... on PRISMIC_PageBodyParagraph___image {
            type
            primary {
              section_id
              image_position
              content
              image: paragraph_image
              imageSharp: paragraph_imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 600) {
                    ...GatsbyImageSharpFixed
                  }
                  mobile: fixed(width: 400) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyPerson_cta {
            type
            label
            primary {
              person {
                ... on PRISMIC_Person {
                  ...PersonFragment
                }
              }
              person_cta_content
              person_cta_title
            }
            fields {
              person_cta_action
              person_cta_link_text
            }
          }
          ... on PRISMIC_PageBodyPerson_quote {
            type
            label
            primary {
              person {
                ... on PRISMIC_Person {
                  first_name
                  role
                }
              }
              person_position
              quote
              person_quote_image
              person_quote_imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 600) {
                    ...GatsbyImageSharpFixed
                  }
                  mobile: fixed(width: 400) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyPerson_repeater {
            type
            primary {
              persons_content
              persons_title
            }
            fields {
              person {
                ... on PRISMIC_Person {
                  ...PersonFragment
                }
              }
            }
          }
        }
      }
    }
  }
`
